<script setup>
import { ref } from 'vue'

const msg = ref('Hello from Site setup Component 1223!')
</script>

<template>
    <h1>{{ msg }}</h1>
    <input v-model="msg">
</template>

